import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import PostDetail from "./pages/PostDetail";


function App() {
  return (
    <Router>
        <Header name="Posts" />
        <Switch>
          <Route path="/post/:postId">
            <PostDetail />
          </Route>
          <Route path="/" exact>
            <Home />
          </Route>
        </Switch>
    </Router>
  );
}

export default App;
