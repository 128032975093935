import { Box, Flex, Text, Button, Image } from "@chakra-ui/react";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";

const Post = ({
  id,
  title,
  username,
  content,
  image,
  timestamp,
  voteCount,
  upVote,
  downVote,
}) => {
  const date = new Date(timestamp);

  return (
    <Box
      p={4}
      border="1px"
      borderColor="gray.200"
      borderRadius="5px"
      marginY={4}
    >
      <Flex justify="space-between">
        <Flex direction="column">
          <Text fontSize="lg">{title}</Text>
          <Text as="i" color="gray.400">
            @{username}
          </Text>
          <Text as="i" color="gray.400">
            {`${date.toLocaleTimeString()} ${date.toLocaleDateString()}`}
          </Text>
          <Text fontSize="md">{content}</Text>
          {image && <Image src={image} w="25em" marginTop={5} />}
        </Flex>
        <Flex direction="column" alignItems="center" marginLeft={4}>
          <Button p={0} h="30px" onClick={() => upVote()}>
            <ChevronUpIcon />
          </Button>
          <Text>{voteCount}</Text>
          <Button p={0} h="30px" onClick={() => downVote()}>
            <ChevronDownIcon />
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Post;
