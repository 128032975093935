import { Container } from "@chakra-ui/layout";
import { useParams } from "react-router";
import Post from "../components/Post";

const PostDetail = () => {
  const { postId } = useParams();

  return (
    <>
      <Container w="container.lg">
        <Post
          id={postId}
          title={"TEST"}
          username={"ishaanverma"}
          content={"jsdkfjsdfnksjnfsdjfksjdf"}
          timestamp={1000000000000}
          voteCount={0}
          upVote={() => {}}
          downVote={() => {}}
        />
      </Container>
    </>
  );
};

export default PostDetail;
