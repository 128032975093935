import {
  Container,
  Button,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  VStack,
  useDisclosure,
  Input,
  Textarea,
  FormControl,
  FormLabel,
  Image,
  Text,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import { API_URL } from "../constants";
import { useState } from "react";

const CreatePost = ({ fetchPosts }) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const [error, setError] = useState();
  const [previewImage, setPreviewImage] = useState();

  const createPost = async (values, actions) => {
    const data = {
      title: values.title,
      username: values.username,
      content: values.content,
      image: previewImage ? previewImage : null,
    };

    try {
      await axios.post(`${API_URL}/post`, data);
      setPreviewImage(null);
      setError("");
      onToggle(false);
      await fetchPosts();
    } catch (err) {
      console.log("Could not submit data");
    }

    actions.setSubmitting(false);
  };

  return (
    <Container maxW="container.lg" marginY={4}>
      <Button onClick={onOpen}>What's on your mind?</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create a Post</ModalHeader>
          <ModalCloseButton />
          <Formik
            initialValues={{
              title: "",
              username: "",
              content: "",
              postImage: "",
            }}
            onSubmit={(values, actions) => createPost(values, actions)}
          >
            {(props) => (
              <Form>
                <ModalBody>
                  <VStack>
                    <Field name="title">
                      {({ field, form }) => (
                        <FormControl>
                          <FormLabel htmlFor="title">Title</FormLabel>
                          <Input
                            {...field}
                            id="title"
                            placeholder="Enter a title"
                            isRequired
                          />
                        </FormControl>
                      )}
                    </Field>
                    <Field name="username">
                      {({ field, form }) => (
                        <FormControl>
                          <FormLabel htmlFor="username">Username</FormLabel>
                          <Input
                            {...field}
                            id="username"
                            placeholder="Enter a username"
                            isRequired
                          />
                        </FormControl>
                      )}
                    </Field>
                    <Field name="postImage">
                      {({ field }) => (
                        <FormControl>
                          <FormLabel htmlFor="postImage">
                            Upload an image
                          </FormLabel>
                          <Input
                            {...field}
                            id="postImage"
                            type="file"
                            accept="image/*"
                            onChange={(event) => {
                              if (event.target.files[0].size > 1048576) {
                                setError("Please select a file below 1MB");
                                setPreviewImage(null);
                                return;
                              } else {
                                setError("");
                              }
                              const readFile = new FileReader();
                              readFile.onloadend = () => {
                                setPreviewImage(readFile.result);
                              };
                              readFile.readAsDataURL(event.target.files[0]);
                            }}
                          />
                        </FormControl>
                      )}
                    </Field>
                    {error && <Text>{error}</Text>}
                    {previewImage && <Image src={previewImage} />}
                    <Field name="content">
                      {({ field, form }) => (
                        <FormControl>
                          <FormLabel htmlFor="content">Content</FormLabel>
                          <Textarea
                            {...field}
                            id="content"
                            placeholder="Enter a content"
                            isRequired
                          />
                        </FormControl>
                      )}
                    </Field>
                  </VStack>
                </ModalBody>
                <ModalFooter>
                  <Button variant="ghost" mr={3} onClick={onClose}>
                    Close
                  </Button>
                  <Button
                    colorScheme="blue"
                    type="submit"
                    isLoading={props.isSubmitting}
                  >
                    Create
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default CreatePost;
