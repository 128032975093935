import { useReducer, useCallback, useEffect } from "react";
import axios from "axios";
import { Container, Flex, Button } from "@chakra-ui/react";
import Post from "../components/Post";
import { postReducer } from "../reducers/postReducer";
import { API_URL } from "../constants";
import CreatePost from "../components/CreatePost";

const Home = () => {
  const [posts, dispatchPosts] = useReducer(postReducer, {
    data: [],
    isLoading: false,
    isError: false,
  });

  // Function to fetch posts from API
  const fetchPosts = useCallback(async () => {
    dispatchPosts({ type: "POST_FETCH_INIT" });
    const result = await axios.get(`${API_URL}/post`);

    try {
      dispatchPosts({
        type: "POST_FETCH_SUCCESS",
        payload: result.data,
      });
    } catch {
      dispatchPosts({ type: "POST_FETCH_FAILURE" });
    }
  }, []);

  const upVotePost = async (id) => {
    try {
      await axios.post(`${API_URL}/post/${id}/upvote`);
      dispatchPosts({
        type: "UPVOTE_POST",
        payload: id,
      });
    } catch (err) {
      console.log("Error Upvoting post");
    }
  };

  const downVotePost = async (id) => {
    try {
      await axios.post(`${API_URL}/post/${id}/downvote`);
      dispatchPosts({
        type: "DOWNVOTE_POST",
        payload: id,
      });
    } catch (err) {
      console.log("Error Upvoting post");
    }
  };

  const sortByNewest = () => {
    dispatchPosts({
      type: "SORT_NEWEST",
    });
  }

  const sortByUpvoted = () => {
    dispatchPosts({
      type: "SORT_UPVOTED",
    });
  }

  const sortByDownvoted = () => {
    dispatchPosts({
      type: "SORT_DOWNVOTED",
    });
  }

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  return (
    <>
      <CreatePost fetchPosts={() => fetchPosts()} />
      <Container>
        <Flex direction="horizontal" justifyContent="space-between">
          <Button onClick={() => sortByNewest()}>Newest</Button>
          <Button onClick={() => sortByUpvoted()}>Most Upvoted</Button>
          <Button onClick={() => sortByDownvoted()}>Most Downvoted</Button>
        </Flex>
      </Container>
      <Container maxW="container.lg">
        {posts.isError && <div>Error!</div>}
        {posts.isLoading ? (
          <div>Loading...</div>
        ) : (
          <PostList
            posts={posts.data ? posts.data : []}
            upVotePost={(id) => upVotePost(id)}
            downVotePost={(id) => downVotePost(id)}
          />
        )}
      </Container>
    </>
  );
};

const PostList = ({ posts, upVotePost, downVotePost }) =>
  posts.map((item, index) => (
    <Post
      key={item.id || index}
      id={item.id || index}
      title={item.title}
      username={item.username}
      content={item.content}
      image={item.image}
      timestamp={item.timestamp}
      voteCount={item.up_votes - item.down_votes}
      upVote={() => upVotePost(item.id)}
      downVote={() => downVotePost(item.id)}
    />
  ));

export default Home;
