import produce from "immer";

export const postReducer = produce(
  (draft, action) => {
    let post;
    switch (action.type) {
      case "POST_FETCH_INIT":
        draft.isLoading = true;
        draft.isError = false;
        break;
      case "POST_FETCH_SUCCESS":
        draft.isLoading = false;
        draft.isError = false;
        draft.data = action.payload;
        break;
      case "POST_FETCH_FAILURE":
        draft.isLoading = false;
        draft.isError = true;
        break;
      case "UPVOTE_POST":
        post = draft.data.find((item) => item.id === action.payload);
        if (post) {
          post.up_votes += 1;
        }
        break;
      case "DOWNVOTE_POST":
        post = draft.data.find((item) => item.id === action.payload);
        if (post) {
          post.down_votes += 1;
        }
        break;
      case "SORT_NEWEST":
        draft.data.sort((a, b) =>
          a.timestamp > b.timestamp ? -1 : a.timestamp < b.timestamp ? 1 : 0
        );
        break;
      case "SORT_UPVOTED":
        draft.data.sort((a, b) =>
          a.up_votes - a.down_votes > b.up_votes - b.down_votes
            ? -1
            : a.up_votes - a.down_votes < b.up_votes - b.down_votes
            ? 1
            : 0
        );
        break;
      case "SORT_DOWNVOTED":
        draft.data.sort((a, b) =>
          a.up_votes - a.down_votes > b.up_votes - b.down_votes
            ? 1
            : a.up_votes - a.down_votes < b.up_votes - b.down_votes
            ? -1
            : 0
        );
        break;
      default:
        break;
    }
  },
  {
    data: [],
    isLoading: false,
    isError: false,
  }
);
